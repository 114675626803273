<template>
  <v-row class="login-app ma-0">
    <div class="d-none d-md-flex flex-column">
      <h1 class="white--text font-weight-black text-h4" v-html="t('title')"></h1>
      <v-divider class="divider mt-3"></v-divider>
    </div>
    <div
      class="fill-height d-flex flex-column align-center background-start login-app_form"
    >
      <AppBar :app="!$vuetify.breakpoint.mdAndUp"  :color="config.color" disable-help />

      <div class="mt-16 flex text-center mb-2 title font-weight-bold primary--text">
        Login
      </div>
      <v-img
        class="mb-n10"
        max-width="290px"
        width="100%"
        height="auto"
        :src="require('@/assets/endIlustration.png')"
      ></v-img>

      <v-container
        class="fill-height d-flex flex-column align-center justify-center  mt-16"
      >
        <v-card-text class="text-center body-2 mt-n2">
          <div>{{ t("description") }}</div>
        </v-card-text>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12">
              <TextField
                :title="t('reservation')"
                :placeholder="t('reservationPlaceHolder')"
                v-model="form.reference"
              />
            </v-col>
            <v-col cols="12" class="mt-n3">
              <TextField
                :title="t('lastName')"
                :placeholder="t('lastNamePlaceHolder')"
                v-model="form.client"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-btn
          class="font-weight-bold mt-4"
          color="primary"
          rounded
          min-width="125px"
          @click="enter()"
          >{{ t("btnAccess") }}</v-btn
        >
      </v-container>
      <DialogHelp v-model="dialogHelp"
        ><div class="font-weight-bold text-center">
          {{ $t("info.help.noexistreservation") }}
        </div></DialogHelp
      >
    </div>
  </v-row>
</template>

<script>
import AppBar from "@/components/AppBar";
import TextField from "@/components/field/TextField";
import DialogHelp from "@/components/dialog/DialogHelp";
import { mapActions } from "vuex";
export default {
  components: {
    AppBar,
    DialogHelp,
    TextField
  },
  data: () => ({
    config: {
      color: "primary40"
    },
    dialogHelp: false,
    form: {
      client: null,
      reference: null
    }
  }),
  methods: {
    ...mapActions("auth", ["_loginApp"]),

    enter() {
      if (!this.$refs.form.validate()) return;
      this._loginApp({
        client: this.form.client,
        reference: this.form.reference
      }).catch(() => {
        this.dialogHelp = true;
      });
    },
    t(key, options = {}) {
      return this.$t(`section.login.${key}`, options);
    }
  }
};
</script>

<style lang="scss" scoped>
.background-start {
  background-image: url("~@/assets/startBackground.svg");
  background-attachment: initial;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 40%;
}
.login-app {
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 1144px;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  .divider {
    background-color: white !important;
  }
  h1 {
    max-width: 300px;
    text-align: center;
  }
  &_form {
    background-color: white;
    max-height: 681px;
    max-width: 375px;
    box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px;
  }

}
@media (min-width: 961px) {
  .v-app-bar {
    position: absolute;
    left: auto !important;
    right: auto !important;
    width: 375px;
  }
}
@media (max-width: 960px) {
  .login-app {
    &_form {
      background-color: white;
      max-height: 100%;
      min-width: 100%;
    }

  }
}
</style>
